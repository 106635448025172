import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Image } from 'cloudinary-react';
import {
  Snackbar,
  Alert,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import XIcon from '@mui/icons-material/X';
import { useUser } from '@clerk/clerk-react';
import { styled } from '@mui/material/styles';
import { getApiUrl } from '../utils/apiUrl';

// アイコンのカスタムスタイル
const StyledMoreVertIcon = styled(MoreVertIcon)({
  fontSize: 35,
});

const StyledXIcon = styled(XIcon)({
  fontSize: 40,
});

/**
 * 画像表示用コンポーネント
 */
const ImageGallery = ({ works }) => {
  const containerClass = `image-container-${works.length}`;
  return (
    <div className={containerClass}>
      {works.map((work, index) => (
        <Image
          key={index}
          cloudName="dputyeqso"
          publicId={work.image}
          className={
            works.length === 1
              ? 'w-[250px] h-[250px] md:w-[500px] md:h-[500px]'
              : 'w-[122.5px] h-[122.5px] md:w-[247.5px] md:h-[247.5px]'
          }
        />
      ))}
    </div>
  );
};

/**
 * ユーザー情報表示用ヘッダーコンポーネント
 */
const UserHeader = ({ user, createdAt, currentUserId, onMenuClick, navigate }) => {
  const dateOptions = { month: 'long', day: 'numeric' };
  const formattedDate = new Date(createdAt).toLocaleDateString('ja-JP', dateOptions);

  return (
    <div className="flex items-center justify-between md:pl-16 lg:pl-32">
      <div className="flex items-center">
        <div
          className="flex items-center cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/users/${user.clerkId}`);
          }}
        >
          <div className="w-12 h-12 rounded-full overflow-hidden md:w-20 md:h-20">
            <img
              src={user.avatarUrl}
              alt="profileImage"
              className="w-full h-full object-cover transition-all duration-300 hover:brightness-90"
            />
          </div>
          <div className="ml-2 md:ml-4">
            <h1>
              <span className="text-lg font-medium md:font-normal hover:underline cursor-pointer md:text-2xl">
                {user.username}
              </span>
            </h1>
          </div>
        </div>
        <span className="ml-2 hover:underline cursor-pointer md:ml-4">{formattedDate}</span>
      </div>
      {currentUserId === user.clerkId && (
        <div className="md:mr-16 lg:mr-32 relative">
          <div
            className="hover:bg-gray-200 p-2 rounded-full inline-block cursor-pointer"
            onClick={onMenuClick}
          >
            <StyledMoreVertIcon />
          </div>
        </div>
      )}
    </div>
  );
};

/**
 * 投稿詳細表示コンポーネント (PostShow.jsx)
 */
const PostShow = () => {
  const { postId } = useParams();
  const navigate = useNavigate();
  const { user: currentUser } = useUser();

  // 投稿データ、メディア、MBTI 情報、ローディング状態の管理
  const [post, setPost] = useState(null);
  const [mediaWorks, setMediaWorks] = useState([]);
  const [mbti, setMbti] = useState(null);
  const [loading, setLoading] = useState(true);

  // メニュー／ダイアログ／スナックバー用の状態管理
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        const apiUrl = getApiUrl();
        const postRes = await fetch(`${apiUrl}/posts/${postId}`);
        const postData = await postRes.json();
        setPost({
          ...postData,
          user: {
            ...postData.user,
            avatarUrl: postData.user.avatar_url,
            username: postData.user.username,
            clerkId: postData.user.clerk_id,
          },
          createdAt: postData.created_at,
        });
        setMediaWorks(postData.media_works);

        try {
          const mbtiRes = await fetch(`${apiUrl}/users/${postData.user.clerk_id}/mbti`);
          const mbtiData = await mbtiRes.json();
          if (mbtiData.mbti_type) {
            setMbti(mbtiData);
          }
        } catch (error) {
          console.error(
            'PostShow.jsx: fetchPostData - MBTI情報の取得中にエラーが発生しました:',
            error
          );
        }
      } catch (error) {
        console.error(
          'PostShow.jsx: fetchPostData - 投稿情報の取得中にエラーが発生しました:',
          error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchPostData();
  }, [postId]);

  // メニュー／ダイアログ操作用関数
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => {
    setOpenDialog(false);
    handleMenuClose();
  };

  // 投稿削除処理
  const handleDeletePost = async () => {
    try {
      const apiUrl = getApiUrl();
      const deleteRes = await fetch(`${apiUrl}/posts/${postId}`, {
        method: 'DELETE',
      });
      if (!deleteRes.ok) {
        console.error('PostShow.jsx: handleDeletePost - ポストの削除に失敗しました');
        return;
      }
      setOpenDialog(false);
      setSnackbarMessage('ポストを削除しました！');
      setOpenSnackbar(true);
      window.location.href = '/posts';
    } catch (error) {
      console.error(
        'PostShow.jsx: handleDeletePost - ポストの削除中にエラーが発生しました:',
        error
      );
    }
  };

  // Twitter へのシェア処理
  const shareToTwitter = () => {
    const apiUrl = getApiUrl();
    const ogPageUrl = `${apiUrl}/posts/${post.id}/ogp_page`;
    let tweetText = '';

    if (mediaWorks?.length > 0) {
      const mediaType = mediaWorks[0].media_type === 'anime' ? 'アニメ' : '音楽アーティスト';
      const mbtiText = mbti?.visibility === 'is_public' ? `(${mbti.mbti_type})` : '';
      tweetText = `${post.user.username}${mbtiText}の好きな${mediaType}は${mediaWorks
        .map((work) => work.title)
        .join('、')}です！`;
    }
    const hashtag = '#MBTIデータベース';
    const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetText + '\n\n' + hashtag + '\n'
    )}&url=${encodeURIComponent(ogPageUrl)}`;
    window.open(shareUrl, '_blank');
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <div className="loading loading-spinner loading-lg text-custom"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="px-4 md:px-0">
      {post?.user && (
        <div className="mt-10">
          <UserHeader
            user={post.user}
            createdAt={post.createdAt}
            currentUserId={currentUser?.id}
            onMenuClick={handleMenuOpen}
            navigate={navigate}
          />
        </div>
      )}

      <div className="mb-3 md:mb-5">
        <div className="text-base px-12 w-full text-center md:text-xl md:px-36 lg:px-40">
          {post.user.username}
          {mbti?.visibility === 'is_public' && `(${mbti.mbti_type})`} の好きな
          {mediaWorks[0]
            ? mediaWorks[0].media_type === 'anime'
              ? 'アニメ'
              : '音楽アーティスト'
            : ''}
          は
          {mediaWorks.map((work, index, array) => (
            <span key={index}>
              {work.title}
              {index < array.length - 1 ? '、' : ''}
            </span>
          ))}
          です！
        </div>
      </div>

      <div className="relative w-full mb-3 md:mb-5">
        <div className="flex justify-center">
          <div className="bg-black">
            <ImageGallery works={mediaWorks} />
          </div>
        </div>
        {currentUser?.id === post.user.clerkId && (
          <div
            className="absolute bottom-0 right-0 rounded-full hover:bg-gray-200 cursor-pointer md:left-[700px] lg:left-[1270px] w-12 h-12 flex items-center justify-center"
            onClick={(e) => {
              e.stopPropagation();
              shareToTwitter();
            }}
          >
            <StyledXIcon />
          </div>
        )}
      </div>

      <hr className="border-t border-[#2EA9DF] w-screen -mx-4 md:-mx-0" />

      {/* 投稿削除用メニュー */}
      <Menu
        id="post-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleDialogOpen}>
          <DeleteOutlineOutlinedIcon fontSize="small" style={{ marginRight: '8px' }} />
          削除
        </MenuItem>
      </Menu>

      {/* 削除確認ダイアログ */}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{ invisible: true }}
        PaperProps={{
          style: {
            boxShadow:
              '0px 1px 3px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.06), 0px 1px 1px -1px rgba(0,0,0,0.04)',
            borderRadius: '16px',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{'ポストの削除'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ポストを完全に削除しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            sx={{
              borderRadius: '20px',
              ':hover': {
                boxShadow: '0px 4px 20px rgba(173, 216, 230, 1)',
              },
            }}
          >
            キャンセル
          </Button>
          <Button
            onClick={handleDeletePost}
            autoFocus
            sx={{
              borderRadius: '20px',
              ':hover': {
                boxShadow: '0px 4px 20px rgba(173, 216, 230, 1)',
              },
            }}
          >
            削除
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openSnackbar} autoHideDuration={2500} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PostShow;
