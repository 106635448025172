import React, { useState, useEffect } from 'react';
import { Image } from 'cloudinary-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import {
  Snackbar,
  Alert,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import XIcon from '@mui/icons-material/X';
import Database from './database';
import { styled } from '@mui/material/styles';
import { getApiUrl } from '../utils/apiUrl';

const StyledMoreVertIcon = styled(MoreVertIcon)({
  fontSize: 35,
});

const StyledXIcon = styled(XIcon)({
  fontSize: 40,
});

/**
 * PostCardコンポーネント
 * - 各ポストの詳細表示
 * - 削除用メニュー / ダイアログ、シェアボタンを含む
 */
const PostCard = ({ post, currentUser, onDelete, onShare, navigate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const openMenu = Boolean(anchorEl);

  // メニュー表示用ハンドラ
  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = (event) => {
    event.stopPropagation();
    setOpenDialog(true);
    handleCloseMenu();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // 削除リクエストを親へ通知
  const handleDelete = () => {
    onDelete(post.id);
    handleCloseDialog();
  };

  // メディア作品画像のレンダリング
  const renderImages = (works) => {
    const containerClass = `image-container-${works.length}`;
    return (
      <div className={containerClass}>
        {works.map((work, index) => (
          <Image
            key={index}
            cloudName="dputyeqso"
            publicId={work.image}
            className={
              works.length === 1
                ? 'w-[250px] h-[250px] md:w-[500px] md:h-[500px]'
                : 'w-[122.5px] h-[122.5px] md:w-[247.5px] md:h-[247.5px]'
            }
          />
        ))}
      </div>
    );
  };

  // 日付フォーマット
  const dateOptions = { month: 'long', day: 'numeric' };
  const formattedDate = new Date(post.createdAt).toLocaleDateString('ja-JP', dateOptions);

  return (
    <div onClick={() => navigate(`/posts/${post.id}`)} className="cursor-pointer">
      {/* ユーザー情報 */}
      <div className="mt-5">
        <div className="flex items-center justify-between md:pl-16 lg:pl-32">
          <div className="flex items-center">
            <div
              className="flex items-center cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/users/${post.user.clerkId}`);
              }}
            >
              <div className="w-12 h-12 rounded-full overflow-hidden md:w-20 md:h-20">
                <img
                  src={post.user.avatarUrl}
                  alt="profileImage"
                  className="w-full h-full object-cover transition-all duration-300 hover:brightness-90"
                />
              </div>
              <div className="ml-2 md:ml-4">
                <h1>
                  <span className="text-lg font-medium md:font-normal hover:underline cursor-pointer md:text-2xl">
                    {post.user.username}
                  </span>
                </h1>
              </div>
            </div>
            <span className="ml-2 hover:underline cursor-pointer md:ml-4">{formattedDate}</span>
          </div>
          {/* 自身のポストの場合はメニュー表示 */}
          {currentUser?.id === post.user.clerkId && (
            <div className="md:mr-16 lg:mr-32 relative">
              <div
                className="hover:bg-gray-200 md:p-2 rounded-full inline-block cursor-pointer"
                onClick={handleMenuClick}
              >
                <StyledMoreVertIcon />
              </div>
              <Menu
                id="post-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                PaperProps={{
                  style: {
                    maxHeight: 48 * 4.5,
                    width: '20ch',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                  },
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={handleOpenDialog}>
                  <DeleteOutlineOutlinedIcon fontSize="small" style={{ marginRight: '8px' }} />
                  削除
                </MenuItem>
              </Menu>
              <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                BackdropProps={{ invisible: true }}
                PaperProps={{
                  style: {
                    boxShadow:
                      '0px 1px 3px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.06), 0px 1px 1px -1px rgba(0,0,0,0.04)',
                    borderRadius: '16px',
                  },
                }}
              >
                <DialogTitle id="alert-dialog-title">{'ポストの削除'}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    ポストを完全に削除しますか？
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCloseDialog();
                    }}
                    sx={{
                      borderRadius: '20px',
                      ':hover': {
                        boxShadow: '0px 4px 20px rgba(173, 216, 230, 1)',
                      },
                    }}
                  >
                    キャンセル
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete();
                    }}
                    autoFocus
                    sx={{
                      borderRadius: '20px',
                      ':hover': {
                        boxShadow: '0px 4px 20px rgba(173, 216, 230, 1)',
                      },
                    }}
                  >
                    削除
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )}
        </div>
      </div>

      {/* ポスト本文 */}
      <div className="mb-3 md:mb-5">
        <div className="text-base px-12 w-full text-center md:text-xl md:px-36 lg:px-40">
          {post.user.username}
          {post.mbti && post.mbti.visibility === 'is_public' ? `(${post.mbti.mbti_type})` : ''}
          の好きな
          {post.mediaWorks && post.mediaWorks[0] ? (
            <>{post.mediaWorks[0].media_type === 'anime' ? 'アニメ' : '音楽アーティスト'}</>
          ) : (
            ''
          )}
          は
          {post.mediaWorks &&
            post.mediaWorks
              .map((work, index, array) => `${work.title}${index < array.length - 1 ? '、' : ''}`)
              .join('')}
          です！
        </div>
      </div>

      {/* メディア画像とシェアボタン */}
      <div className="relative w-full mb-3 md:mb-5">
        <div className="flex justify-center">
          <div className="bg-black">{post.mediaWorks && renderImages(post.mediaWorks)}</div>
        </div>
        {currentUser?.id === post.user.clerkId && (
          <div
            className="absolute bottom-0 right-0 rounded-full hover:bg-gray-200 cursor-pointer md:left-[700px] lg:left-[1270px] w-12 h-12 flex items-center justify-center"
            onClick={(e) => {
              e.stopPropagation();
              onShare(post);
            }}
          >
            <StyledXIcon />
          </div>
        )}
      </div>
      <hr className="border-t border-[#2EA9DF] w-screen -mx-4 md:-mx-0" />
    </div>
  );
};

const PostsIndex = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSection, setSelectedSection] = useState('home');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const { user: currentUser } = useUser();
  const API_URL = getApiUrl(); // 外部ファイルから取得

  // ポスト削除の処理
  const handleDeletePost = async (postId) => {
    try {
      const deleteRes = await fetch(`${API_URL}/posts/${postId}`, {
        method: 'DELETE',
      });
      if (deleteRes.ok) {
        setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
        setOpenSnackbar(true);
        setSnackbarMessage('ポストを削除しました！');
      } else {
        console.error('PostsIndex.jsx / handleDeletePost: ポストの削除に失敗しました');
      }
    } catch (error) {
      console.error(
        'PostsIndex.jsx / handleDeletePost: ポスト削除中にエラーが発生しました:',
        error
      );
    }
  };

  // Twitterシェア機能
  const shareToX = (post) => {
    const ogPageUrl = `${API_URL}/posts/${post.id}/ogp_page`;
    let artistText = '';

    if (post.mediaWorks && post.mediaWorks[0]) {
      const mediaType = post.mediaWorks[0].media_type === 'anime' ? 'アニメ' : '音楽アーティスト';
      const mbtiText =
        post.mbti && post.mbti.visibility === 'is_public' ? `(${post.mbti.mbti_type})` : '';
      artistText = `${post.user.username}${mbtiText}の好きな${mediaType}は${post.mediaWorks
        .map((work, index, array) => `${work.title}${index < array.length - 1 ? '、' : ''}`)
        .join('')}です！`;
    }

    const hashtag = '#MBTIデータベース';
    const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      artistText + '\n\n' + hashtag + '\n'
    )}&url=${encodeURIComponent(ogPageUrl)}`;
    window.open(shareUrl, '_blank');
  };

  // ポストと関連情報の取得
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const postsRes = await fetch(`${API_URL}/posts`);
        const postsData = await postsRes.json();

        // 初期化：createdAt の設定とユーザー情報の初期値
        const postsInitial = postsData.map((post) => ({
          ...post,
          user: {
            ...post.user,
            avatarUrl: null,
            username: null,
            clerkId: post.user.clerk_id,
          },
          createdAt: post.created_at,
        }));

        // 各ポストの詳細情報（ユーザー・メディア作品・MBTI）を並列取得
        const postsWithDetails = await Promise.all(
          postsInitial.map(async (post) => {
            try {
              const [userRes, mediaRes, mbtiRes] = await Promise.all([
                fetch(`${API_URL}/users/${post.user.clerk_id}`),
                fetch(`${API_URL}/posts/${post.id}/media_works`),
                fetch(`${API_URL}/users/${post.user.clerk_id}/mbti`),
              ]);
              const userData = await userRes.json();
              const mediaData = await mediaRes.json();
              const mbtiData = mbtiRes.ok ? await mbtiRes.json() : null;
              return {
                ...post,
                user: {
                  ...post.user,
                  avatarUrl: userData.avatar_url,
                  username: userData.username,
                  clerkId: userData.clerk_id,
                },
                mediaWorks: mediaData,
                mbti: mbtiData,
              };
            } catch (error) {
              console.error(
                'PostsIndex.jsx / fetchPostDetails: ポスト詳細の取得中にエラーが発生しました:',
                error
              );
              return post; // エラー時はそのまま返す
            }
          })
        );
        setPosts(postsWithDetails);
      } catch (error) {
        console.error('PostsIndex.jsx / fetchPosts: ポストの取得中にエラーが発生しました:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, [API_URL, location.pathname]);

  // セクション切替
  const selectSection = (section) => {
    setSelectedSection(section);
  };

  // ポスト送信成功時のSnackbar表示（location.state利用）
  useEffect(() => {
    if (location.state?.postSuccess) {
      setOpenSnackbar(true);
      setSnackbarMessage('ポストを送信しました！');
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, navigate]);

  // セクションごとのコンテンツ切替
  const renderContent = () => {
    if (selectedSection === 'home') {
      return isLoading ? (
        <div className="flex items-center justify-center h-screen">
          <div className="text-center">
            <div className="loading loading-spinner loading-lg text-custom"></div>
          </div>
        </div>
      ) : (
        posts.map((post) => (
          <PostCard
            key={post.id}
            post={post}
            currentUser={currentUser}
            onDelete={handleDeletePost}
            onShare={shareToX}
            navigate={navigate}
          />
        ))
      );
    } else if (selectedSection === 'database') {
      return (
        <div className="w-full max-w-7xl mx-auto">
          <Database />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="px-4 md:px-0">
      {/* セクション切替ナビゲーション */}
      <div className="fixed top-16 md:top-12 left-0 right-0 bg-off-white z-10">
        <div className="flex justify-between items-center mt-4 w-full max-w-xl mx-auto md:mt-8 md:max-w-2xl">
          <div
            className="flex-1 text-center cursor-pointer text-lg md:text-xl sidebar-link"
            onClick={() => selectSection('database')}
          >
            <span>データベース</span>
            <div
              className={
                selectedSection === 'database'
                  ? 'border-b-4 border-[#2EA9DF] w-1/2 mx-auto rounded-lg'
                  : ''
              }
            ></div>
          </div>
          <div
            className="flex-1 text-center cursor-pointer text-lg md:text-xl sidebar-link"
            onClick={() => selectSection('home')}
          >
            <span>ホーム</span>
            <div
              className={
                selectedSection === 'home'
                  ? 'border-b-4 border-[#2EA9DF] w-1/2 mx-auto rounded-lg'
                  : ''
              }
            ></div>
          </div>
        </div>
        <hr className="border-t border-[#91989F] w-full" />
      </div>

      <div className="mt-20 md:mt-24">{renderContent()}</div>

      <Snackbar open={openSnackbar} autoHideDuration={2500} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PostsIndex;
