import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Image } from 'cloudinary-react';
import MBTIModal from './MBTIModal';
import { useUser } from '@clerk/clerk-react';
import { useUserContext } from '../contexts/UserContext';
import { useParams, useNavigate } from 'react-router-dom';
import { getApiUrl } from '../utils/apiUrl';

// MUI関連のインポート
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import XIcon from '@mui/icons-material/X';
import { styled } from '@mui/material/styles';
import {
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';

// Styled Components
const StyledMoreVertIcon = styled(MoreVertIcon)({
  fontSize: 35,
});
const StyledXIcon = styled(XIcon)({
  fontSize: 40,
});

// ──────────────
// 投稿ヘッダーコンポーネント
// ──────────────
const PostHeader = ({ profile, post, navigate, onMenuOpen, isOwnProfile }) => {
  const dateOptions = { month: 'long', day: 'numeric' };
  const formattedDate = new Date(post.created_at).toLocaleDateString('ja-JP', dateOptions);

  return (
    <div className="flex items-center justify-between md:pl-16 lg:pl-32">
      <div className="flex items-center">
        <div
          className="flex items-center cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/users/${profile.clerkId || ''}`);
          }}
        >
          <div className="w-12 h-12 rounded-full overflow-hidden md:w-20 md:h-20">
            <img
              src={profile.avatarUrl || 'デフォルトのアバター画像URL'}
              alt="profileImage"
              className="w-full h-full object-cover transition-all duration-300 hover:brightness-90"
            />
          </div>
          <div className="ml-2 md:ml-4">
            <h1>
              <span className="text-lg font-medium md:font-normal hover:underline cursor-pointer md:text-2xl">
                {profile.username || 'Unknown User'}
              </span>
            </h1>
          </div>
        </div>
        <span className="ml-2 hover:underline cursor-pointer md:ml-4">{formattedDate}</span>
      </div>

      {isOwnProfile && (
        <div className="md:mr-16 lg:mr-32 relative">
          <div
            className="hover:bg-gray-200 p-2 rounded-full inline-block cursor-pointer"
            onClick={(event) => onMenuOpen(event, post.id)}
          >
            <StyledMoreVertIcon />
          </div>
        </div>
      )}
    </div>
  );
};

// ──────────────
// 画像表示コンポーネント
// ──────────────
const PostImages = ({ works }) => {
  if (!works) return null;
  const containerClass = `image-container-${works.length}`;

  return (
    <div className={containerClass}>
      {works.map((work, index) => (
        <Image
          key={index}
          cloudName="dputyeqso"
          publicId={work.image}
          className={
            works.length === 1
              ? 'w-[250px] h-[250px] md:w-[500px] md:h-[500px]'
              : 'w-[122.5px] h-[122.5px] md:w-[247.5px] md:h-[247.5px]'
          }
        />
      ))}
    </div>
  );
};

// ──────────────
// 1件の投稿表示コンポーネント
// ──────────────
const PostItem = ({
  post,
  profile,
  userMbtiType,
  mbtiVisibility,
  navigate,
  onMenuOpen,
  onShare,
  isOwnProfile,
}) => {
  const mediaType =
    post.mediaWorks && post.mediaWorks[0]?.media_type === 'anime' ? 'アニメ' : '音楽アーティスト';

  const mbtiText = mbtiVisibility === 'is_public' && userMbtiType ? `(${userMbtiType})` : '';

  const postText = (
    <>
      {profile.username}
      {mbtiText}の好きな{mediaType}は{' '}
      {post.mediaWorks?.map((work, idx, arr) =>
        idx < arr.length - 1 ? `${work.title}、` : work.title
      )}
      です！
    </>
  );

  return (
    <React.Fragment key={post.id}>
      <div onClick={() => navigate(`/posts/${post.id}`)} className="cursor-pointer">
        <div className="mt-5">
          <PostHeader
            profile={profile}
            post={post}
            navigate={navigate}
            onMenuOpen={onMenuOpen}
            isOwnProfile={isOwnProfile}
          />
        </div>
        <div className="mb-3 md:mb-5">
          <div className="text-base px-12 w-full text-center md:text-xl md:px-36 lg:px-52">
            {postText}
          </div>
        </div>
        <div className="relative w-full mb-3 md:mb-5">
          <div className="flex justify-center">
            <div className="bg-black">
              <PostImages works={post.mediaWorks} />
            </div>
          </div>
          {isOwnProfile && (
            <div
              className="absolute bottom-0 right-0 rounded-full hover:bg-gray-200 cursor-pointer md:left-[700px] lg:left-[1270px] w-12 h-12 flex items-center justify-center"
              onClick={(e) => {
                e.stopPropagation();
                onShare(post);
              }}
            >
              <StyledXIcon />
            </div>
          )}
        </div>
      </div>
      <hr className="border-t border-[#2EA9DF] w-screen -mx-4 md:-mx-0" />
    </React.Fragment>
  );
};

function UserProfile() {
  // ステートの定義
  const [profile, setProfile] = useState(null);
  const [mbtiType, setMbtiType] = useState(null);
  const [userPosts, setUserPosts] = useState([]);
  const [userMbtiType, setUserMbtiType] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSection, setSelectedSection] = useState('posts');
  const [showMBTIModal, setShowMBTIModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [deletePostId, setDeletePostId] = useState(null);

  const { user } = useUser();
  const { isProfileUpdated } = useUserContext();
  const { clerkId } = useParams();
  const navigate = useNavigate();

  // 自分のプロフィールかどうかの判定
  const isOwnProfile = useMemo(() => {
    return user && profile && user.id === profile.clerkId;
  }, [user, profile]);

  // ──────────────
  // APIからデータを取得する関数群
  // ──────────────

  // ユーザープロフィール取得
  const fetchUserProfile = useCallback(async (targetClerkId) => {
    try {
      const res = await fetch(`${getApiUrl()}/users/${targetClerkId}`);
      const data = await res.json();
      setProfile({
        username: data.username,
        avatarUrl: data.avatar_url,
        clerkId: data.clerk_id,
      });
    } catch (error) {
      console.error(
        `[UserProfile] ユーザープロフィールの取得に失敗しました: ${error.message}`,
        error
      );
    }
  }, []);

  // MBTI情報取得
  const fetchUserMBTI = useCallback(async (targetClerkId) => {
    try {
      const res = await fetch(`${getApiUrl()}/users/${targetClerkId}/mbti`);
      const data = await res.json();
      if (data.mbti_type) {
        setMbtiType(data);
        setUserMbtiType(data.mbti_type);
      }
    } catch (error) {
      console.error(`[UserProfile] MBTI情報の取得に失敗しました: ${error.message}`, error);
    }
  }, []);

  // ユーザー投稿一覧（各投稿にmedia_works情報を付加）
  const fetchUserPosts = useCallback(async (targetClerkId) => {
    try {
      const res = await fetch(`${getApiUrl()}/users/${targetClerkId}/posts`);
      const postsData = await res.json();
      const postsWithMedia = await Promise.all(
        postsData.map(async (post) => {
          try {
            const mediaRes = await fetch(`${getApiUrl()}/posts/${post.id}/media_works`);
            const mediaData = await mediaRes.json();
            return { ...post, mediaWorks: mediaData };
          } catch (error) {
            console.error(
              `[UserProfile] ポスト ${post.id} のメディアワークの取得に失敗しました: ${error.message}`,
              error
            );
            return { ...post, mediaWorks: [] };
          }
        })
      );
      setUserPosts(postsWithMedia);
    } catch (error) {
      console.error(`[UserProfile] ユーザー投稿の取得に失敗しました: ${error.message}`, error);
    }
  }, []);

  // ──────────────
  // データ初期取得用のEffect
  // ──────────────
  useEffect(() => {
    const targetClerkId = clerkId || user?.id;
    if (!targetClerkId) return;
    (async () => {
      setIsLoading(true);
      await Promise.all([
        fetchUserProfile(targetClerkId),
        fetchUserMBTI(targetClerkId),
        fetchUserPosts(targetClerkId),
      ]);
      setIsLoading(false);
    })();
  }, [clerkId, user, isProfileUpdated, fetchUserProfile, fetchUserMBTI, fetchUserPosts]);

  // ──────────────
  // 各種ハンドラー
  // ──────────────

  // メニュー操作（MoreVertIcon押下時）
  const handleMenuOpen = (event, postId) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setDeletePostId(postId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // 削除ダイアログ操作
  const handleOpenDialog = (event) => {
    event.stopPropagation();
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    handleMenuClose();
  };

  const handleDeletePost = async () => {
    if (!deletePostId) return;
    try {
      const res = await fetch(`${getApiUrl()}/posts/${deletePostId}`, { method: 'DELETE' });
      if (!res.ok) throw new Error('投稿の削除に失敗しました');
      setUserPosts((prevPosts) => prevPosts.filter((p) => p.id !== deletePostId));
    } catch (error) {
      console.error(`[UserProfile] ポストの削除に失敗しました: ${error.message}`, error);
    } finally {
      handleCloseDialog();
    }
  };

  // X（旧Twitter）でシェアする処理
  const shareToX = (post) => {
    const ogPageUrl = `${getApiUrl()}/posts/${post.id}/ogp_page`;
    const isPublic = mbtiType?.visibility === 'is_public';
    const mbtiText = isPublic && userMbtiType ? `(${userMbtiType})` : '';
    const mediaType =
      post.mediaWorks && post.mediaWorks[0]?.media_type === 'anime' ? 'アニメ' : '音楽アーティスト';
    const text =
      `${profile.username}${mbtiText}の好きな${mediaType}は ` +
      post.mediaWorks
        .map((work, i, arr) => `${work.title}${i < arr.length - 1 ? '、' : ''}`)
        .join('') +
      'です！';
    const hashtag = '#MBTIデータベース';
    const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      text + '\n\n' + hashtag + '\n'
    )}&url=${encodeURIComponent(ogPageUrl)}`;
    window.open(shareUrl, '_blank');
  };

  // タブ操作
  const selectSection = (section) => setSelectedSection(section);
  const getSelectedStyle = (section) =>
    selectedSection === section ? 'border-b-4 border-[#2EA9DF] w-1/2 mx-auto rounded-lg' : '';

  // 各タブのコンテンツレンダリング
  const renderPostsSection = () => {
    if (userPosts.length === 0 && isOwnProfile) {
      return (
        <div className="flex justify-center items-center h-full mt-8">
          <p className="text-center text-gray-600">
            右下の+ボタンから好きな作品やアーティストをポストしてみよう😌
          </p>
        </div>
      );
    }

    return (
      <div>
        {[...userPosts].reverse().map((post) => (
          <PostItem
            key={post.id}
            post={post}
            profile={profile}
            userMbtiType={userMbtiType}
            mbtiVisibility={mbtiType?.visibility}
            navigate={navigate}
            onMenuOpen={handleMenuOpen}
            onShare={shareToX}
            isOwnProfile={isOwnProfile}
          />
        ))}
      </div>
    );
  };

  const renderPlaceholder = (label) => (
    <div className="text-center mt-4 md:mt-8">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6 inline-block"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21.75 6.75a4.5 4.5 0 0 1-4.884 4.484
             c-1.076-.091-2.264.071-2.95.904l-7.152 8.684
             a2.548 2.548 0 1 1-3.586-3.586l8.684-7.152
             c.833-.686.995-1.874.904-2.95
             a4.5 4.5 0 0 1 6.336-4.486l-3.276 3.276
             a3.004 3.004 0 0 0 2.25 2.25l3.276-3.276
             c.256.565.398 1.192.398 1.852Z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4.867 19.125h.008v.008h-.008v-.008Z"
        />
      </svg>
      {label}は実装予定です。
    </div>
  );

  const renderContent = () => {
    switch (selectedSection) {
      case 'posts':
        return renderPostsSection();
      case 'comments':
        return renderPlaceholder('コメント');
      case 'likes':
        return renderPlaceholder('いいね');
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <div className="loading loading-spinner loading-lg text-custom"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full px-4 md:px-0">
      {profile && (
        <>
          {/* ヘッダー部：アバター、ユーザー名、MBTI情報、MBTI編集ボタン */}
          <div className="flex items-center justify-between w-full pt-8 md:pt-8 md:px-8">
            <div className="avatar">
              <div className="w-16 h-16 rounded-full overflow-hidden md:w-24 md:h-24">
                <img
                  src={profile.avatarUrl || ''}
                  alt="User profile"
                  className="w-full h-full object-cover transition-all duration-300"
                />
              </div>
            </div>
            <div className="ml-4 md:ml-8">
              <h1>
                <span className="text-xl md:text-2xl">{profile.username}</span>{' '}
                <span className="ml-2 md:ml-4">
                  {mbtiType?.visibility === 'is_public' && mbtiType.mbti_type}
                </span>
              </h1>
            </div>
            <div className="ml-auto mb-4 md:mb-12 mr-4 md:mr-16 lg:mr-20">
              {(!clerkId || clerkId === user?.id) && (
                <div
                  tabIndex={0}
                  role="button"
                  onClick={() => setShowMBTIModal(true)}
                  className="p-2 rounded-full hover:bg-gray-200"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m16.862 4.487 1.687-1.688
                         a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82
                         a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685
                         a4.5 4.5 0 0 1 1.13-1.897
                         L16.863 4.487Zm0 0L19.5 7.125"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>

          {/* タブ部分 */}
          <div className="flex justify-between items-center mt-8 md:mt-16 w-full">
            <div
              className="flex-1 text-center cursor-pointer sidebar-link"
              onClick={() => selectSection('posts')}
            >
              <span className="text-lg md:text-xl">ポスト</span>
              <div className={getSelectedStyle('posts')}></div>
            </div>
            <div
              className="flex-1 text-center cursor-pointer sidebar-link"
              onClick={() => selectSection('comments')}
            >
              <span className="text-lg md:text-xl">コメント</span>
              <div className={getSelectedStyle('comments')}></div>
            </div>
            <div
              className="flex-1 text-center cursor-pointer sidebar-link"
              onClick={() => selectSection('likes')}
            >
              <span className="text-lg md:text-xl">いいね</span>
              <div className={getSelectedStyle('likes')}></div>
            </div>
          </div>

          <hr className="border-t border-[#2EA9DF] w-screen -mx-4 md:-mx-0" />

          {/* タブごとのコンテンツ */}
          {renderContent()}
        </>
      )}

      {/* MBTI編集モーダル（自分のプロフィールの場合のみ表示） */}
      {showMBTIModal && (!clerkId || clerkId === user?.id) && (
        <MBTIModal
          onClose={() => setShowMBTIModal(false)}
          onUpdate={(newMbtiType, newVisibility) => {
            fetch(`${getApiUrl()}/users/${user.id}/mbti`, {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                mbti_type: newMbtiType,
                visibility: newVisibility,
              }),
            })
              .then((res) => res.json())
              .then((data) => {
                setMbtiType({
                  mbti_type: data.mbti_type,
                  visibility: data.visibility,
                });
                setUserMbtiType(data.mbti_type);
              })
              .catch((error) => {
                console.error(
                  `[UserProfile] MBTI タイプの更新に失敗しました: ${error.message}`,
                  error
                );
              });
          }}
          initialMBTI={mbtiType?.mbti_type || ''}
          initialVisibility={mbtiType?.visibility || 'is_public'}
        />
      )}

      {/* 投稿削除確認ダイアログ */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{ invisible: true }}
        PaperProps={{
          style: {
            boxShadow:
              '0px 1px 3px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.06), 0px 1px 1px -1px rgba(0,0,0,0.04)',
            borderRadius: '16px',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">ポストの削除</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ポストを完全に削除しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            sx={{
              borderRadius: '20px',
              ':hover': { boxShadow: '0px 4px 20px rgba(173, 216, 230, 1)' },
            }}
          >
            キャンセル
          </Button>
          <Button
            onClick={handleDeletePost}
            autoFocus
            sx={{
              borderRadius: '20px',
              ':hover': { boxShadow: '0px 4px 20px rgba(173, 216, 230, 1)' },
            }}
          >
            削除
          </Button>
        </DialogActions>
      </Dialog>

      {/* 削除用メニュー（MoreVertIcon押下時に表示） */}
      <Menu
        id="delete-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
          },
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={handleOpenDialog}>
          <DeleteOutlineOutlinedIcon fontSize="small" className="mr-2" />
          削除
        </MenuItem>
      </Menu>
    </div>
  );
}

export default UserProfile;
